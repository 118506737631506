import axios from 'axios';
import { BASE_URL } from './constants';
// config


// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: BASE_URL,
 
});

axiosInstance.interceptors.request.use((res)=>{

  if(res){

      res.headers.Authorization="Bearer " +localStorage.getItem('accessToken');
  }
  
  return res;
},(err)=>{
  console.log("intercepter reposne",err.response.data.message)
  if(err.response.data.message==="Unauthenticated"){
  
     
    // localStorage.removeItem('accessToken');
   

  }
  throw err
})

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
// );

export default axiosInstance;
