/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-undef */
import { useState, useEffect, useMemo } from 'react';
import { MaterialReactTable } from 'material-react-table';

// @mui
import {  Container } from '@mui/material';

// redux tool
// import { useSnackbar } from 'notistack';

// routes
// import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Page from '../../components/Page';
// import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { UserChartDetails } from '../../services/dashboardservices/userDetailsChart';

// ----------------------------------------------------------------------

export default function TopUsers() {
  // const { enqueueSnackbar } = useSnackbar();
  const [user] = useState([]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'first_name',
        header: 'First Name',
        size: 50,
      },
      {
        accessorKey: 'last_name',
        header: 'Last Name',
        size: 50,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 50,
      },
      {
        accessorKey: 'phone',
        header: 'Phone',
        size: 50,
      },
      {
        accessorKey: 'city',
        header: 'City',
        size: 50,
      },

      {
        accessorKey: 'province',
        header: 'Province',
        size: 150,
      },
      {
        accessorKey: 'country',
        header: 'Country',
        size: 150,
      },
    ],
    []
  );

  // const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);

  const getAlluser = async () => {
    try {
      const result = await UserChartDetails();

      console.log('top user data is', result.data.signed_up_in_last_thirty_days);
      if (result.data.signed_up_in_last_thirty_days) {
        setTableData(result.data.signed_up_in_last_thirty_days);
      }
    } catch (error) {
      console.log(error, 'accounts');
    }
  };

  useEffect(() => {
    getAlluser();
  }, []);

  useEffect(() => {
    if (user?.length) {
      setTableData(user);
    }
  }, [user]);

  return (
    <Page title="Users">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs heading={`Total User ${tableData.length}`} links={[{ name: '', href: '' }]} />

        <MaterialReactTable
          columns={columns}
          data={tableData}
          // enableRowActions

          // positionActionsColumn="last"
        />
      </Container>
    </Page>
  );
}
