// components
import { PATH_DASHBOARD } from '../../../routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';
import useAuth from "../../../hooks/useAuth";

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;


const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  blog: getIcon('ic_blog'),
  applications: getIcon('ic_kanban'),
  employee: getIcon('ic_eye'),
};






const NavConfig = () => {
  const {user} = useAuth();
    console.log(user)

    return [
      // GENERAL
      // ----------------------------------------------------------------------
      {
        items: [
          { title: 'Dashboard', path: PATH_DASHBOARD.dashboard.home, icon: ICONS.dashboard },
          { title: 'User Management', path: PATH_DASHBOARD.dashboard.users, icon: ICONS.user },
          { title: 'Blog', path: PATH_DASHBOARD.dashboard.blog, icon: ICONS.blog },
          { title: 'Database', path: PATH_DASHBOARD.dashboard.applications, icon: ICONS.applications },
          { title: 'Uploaded Files', path: PATH_DASHBOARD.dashboard.applicationFiles, icon: ICONS.applications },
          { title: 'Hidden Employers', path: PATH_DASHBOARD.dashboard.employee, icon: ICONS.employee },
          // { title: 'User', path: PATH_DASHBOARD.user.list, icon: ICONS.user },
    
    
    
        ],
      },
    
      
    ];
    
  } 
  

    
 


export default NavConfig;