/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-undef */
import { useState, useEffect, useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { MaterialReactTable, MRT_EditActionButtons as MrtTable } from 'material-react-table';
import Swal from 'sweetalert2';
// import { jsPDF } from 'jspdf'; //or use your library of choice here
// import autoTable from 'jspdf-autotable';
import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here

import { Container, Box, Button, IconButton, Tooltip, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Page from '../../components/Page';

import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

import {

  deleteApplicationFileService,
 
  getAllApplicationFilesService,

  updateApplicationsService,
} from '../../services/applicationservices/applicationService';
import FileUploadModal from './FileUploadModal';

import { deleteRowCheckService } from '../../services/dashboardservices/userManagementService';
import { dataContext } from '../../contexts/DataProivder';

// ----------------------------------------------------------------------

export default function ApplicationFilesTable() {
  const [user] = useState([]);
  const navigate = useNavigate()
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isUpdatingUser, setIsUpdatingUser] = useState(false);
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const {setApplicationId} = useContext(dataContext)

  const columns = useMemo(() => [
    
    {
      accessorKey: 'file_name',
      header: 'File Name',
      size: 50,
      enableEditing: false,
      editVariant: '',
    },
   
  ]);


  // const navigate = useNavigate();


  const getAllApplictions = async () => {
    setIsLoadingUsers(true);
    try {
      const result = await getAllApplicationFilesService();
    
      if (result.data) {
        setTableData(result.data);
        setIsLoadingUsers(false);
      }
    } catch (error) {
      setIsLoadingUsers(false);
      console.log(error);
    }
  };



  //  updating user
  const handleUpdateApplication = async ({ values, table }) => {
    setIsUpdatingUser(true);
    setIsLoadingUsers(true);
    const result = await updateApplicationsService(values);

    console.log('handle update user ', result);
    if (result.status === true) {
      setIsUpdatingUser(false);
      setIsLoadingUsers(false);
      setValidationErrors({});
      table.setEditingRow(null);
    } else {
      setIsUpdatingUser(false);
      setIsLoadingUsers(false);
      setValidationErrors({});
    }
  };

  //  User Delete function
  const openDeleteConfirmModal = async (row) => {
    // alert(row.original.id)
    Swal.fire({
      title: 'Do you want to delete a File?',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setIsUpdatingUser(true);
        setIsLoadingUsers(true);

        const reuslt = await deleteApplicationFileService(row.original.id);
        if (reuslt.status) {
          Swal.fire({
            icon: 'success',
            title: 'Deleted!',
            text: 'Application has been deleted successfully.',
          });
          getAllApplictions();
          setIsUpdatingUser(false);
          setIsLoadingUsers(false);
          setValidationErrors({});
        } else {
          setIsUpdatingUser(false);
          setIsLoadingUsers(false);
          setValidationErrors({});
        }
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      }
    });
  };
  //  Application hidden employee function
  const showApplication = async (id) => {
    setApplicationId(id)
    navigate('/dashboard/applications')

  
      
  };
  useEffect(() => {
   
    getAllApplictions();
  }, []);

  useEffect(() => {
    if (user?.length) {
      setTableData(user);
    }
  }, [user]);

  const toggleFileUploadModal = () => {
    setIsFileUploadModalOpen(!isFileUploadModalOpen);
  };

  const DeleteRowCollection = async (data,table) => {
  

    Swal.fire({
      title: 'Do you want to delete an application?',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setIsUpdatingUser(true);
        setIsLoadingUsers(true);

        const reuslt = await await deleteRowCheckService(data);
        if (reuslt.status) {
          Swal.fire({
            icon: 'success',
            title: 'Deleted!',
            text: 'Application has been deleted successfully.',
          });
          getAllApplictions();
          setIsUpdatingUser(false);
          setIsLoadingUsers(false);
          table.setRowSelection({});
          
         
        } else {
          setIsUpdatingUser(false);
          setIsLoadingUsers(false);
          
         
        }
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      }
    });
  };
  return (
    <Page title="Uploaded Application Files">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs heading="Uploaded Application Files" links={[{ name: '', href: '' }]} />

        <MaterialReactTable
          columns={columns}
          data={tableData}
          createDisplayMode="modal" // default ('row', and 'custom' are also available)
          editDisplayMode="modal"
        //   enableRowActions
          // createDisplayMode="row"
          // editDisplayMode="row"
          enableEditing
          onCreatingRowCancel={() => setValidationErrors({})}
         
          onEditingRowCancel={() => setValidationErrors({})}
          onEditingRowSave={handleUpdateApplication}
        //   enableRowSelection={true}
          // onRowSelectionChange={(row) => {console.log("the row selection changed",row)}}
          renderRowActions={({ row, table }) => (
            <>
              <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip title="View Applications">
                  <IconButton onClick={() => showApplication(row.original.id)}>
                    <RemoveRedEyeIcon />
                  </IconButton>
                </Tooltip>
                {/* <Tooltip title="Edit">
                  <IconButton onClick={() => table.setEditingRow(row)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip> */}
              
                <Tooltip title="Delete">
                  <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
          )}
          getRowId={(row) => row.id}
          renderTopToolbarCustomActions={({ table }) => {
            const handleDeactivate = (row) => {
              const dataarray = [];
              table.getSelectedRowModel().flatRows.map((row) => {
                dataarray.push(row.original.id);
              });
             DeleteRowCollection(dataarray,table);
            
            };
            return (
              <Box sx={{ display: 'flex', gap: '1rem' }}>
              
                <Button variant="contained" onClick={toggleFileUploadModal}>
                  Upload
                </Button>
                
                <Button
                color="error"
                // disabled={!table.getIsSomeRowsSelected()}
                onClick={handleDeactivate}
                variant="contained"
                // classes={!table.getIsSomeRowsSelected() ? "hidden":"block"}
                  style={{
                    display: table.getIsSomeRowsSelected()? 'block' : 'none',
                  }}
              >
                Delete All
              </Button>
              </Box>
            );
          }}
          positionActionsColumn="last"
          state={{
            isLoading: isLoadingUsers,
            isSaving: isCreatingUser || isUpdatingUser || isDeletingUser,
          }}
          renderCreateRowDialogContent={({ table, row, internalEditComponents }) => (
            <>
              <DialogTitle variant="h3">Create New Application</DialogTitle>
              <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                {internalEditComponents} {/* or render custom edit components here */}
              </DialogContent>
              <DialogActions>
                <MrtTable variant="text" table={table} row={row} />
              </DialogActions>
            </>
          )}
        />
        {/* <AddUser open={open} setOpen={setOpen} /> */}
        <FileUploadModal
          isOpen={isFileUploadModalOpen}
          setIsOpen={setIsFileUploadModalOpen}
          onClose={toggleFileUploadModal}
          setIsLoadingUsers={setIsLoadingUsers}
          GetData={getAllApplictions}
          // Add any necessary props for file upload functionality
        />
      </Container>
    </Page>
  );
}
