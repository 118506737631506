import axiosInstance from "../../utils/axios"



export const loginAdmin = async(data)=>{
    const result= await axiosInstance.post('/admin/login',data).then((res)=>{
        return res.data
        }).catch((err)=>{
            return err.response.data;
        });  
        return result;
};