/* eslint-disable react/prop-types */
import  { useState } from 'react';
import PropTypes from 'prop-types';
import ReactQuill,{ Quill } from 'react-quill'; // Make sure to import ReactQuill
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';
import BlotFormatter from 'quill-blot-formatter';

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/blotFormatter', BlotFormatter);

function Editor({ placeholder,formik}) {
   const [ editorHtml, setEditorHtml]=useState(formik.values.description)


  const handleChange = (html) => {
    setEditorHtml(html);
    console.log('the html is',html)
    formik.setFieldValue('description',html)

  };


  // useEffect(() => {
  //   document.title = `Editor - ${editorHtml.substring(0, 20)}...`; // Update document title
  // }, [editorHtml]);

  return (
    <div>
      <ReactQuill 
        theme="snow"
        onChange={handleChange}
        value={editorHtml}
        modules={Editor.modules}
        formats={Editor.formats}
        bounds={'.app'}
        placeholder={placeholder}
        className=' text-black-2 dark:text-white  dark:placeholder-white'
        style={{
          height:"300px",
        }}
      />
     
    </div>
  );
}

Editor.propTypes = {
  placeholder: PropTypes.string,
};

// Define Quill modules and formats as properties of the function component

Editor.modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize'],
      
    },
    blotFormatter: {}
  };

  Editor.formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
   
  ];


export default Editor;
