import axiosInstance from "../../utils/axios"


export const getAllUsers = async()=>{
    const result= await axiosInstance.get('/admin/get/all/users').then((res)=>{
        return res.data
        }).catch((err)=>{
            return err.response.data;
        });  
        return result;
};
export const changeStatusService = async(id,status)=>{
    const formdata = new FormData();
    formdata.append('user_id',id);
    formdata.append('status',status);
    const result= await axiosInstance.post(`/admin/change/user/status`,formdata).then((res)=>{
        return res.data
        }).catch((err)=>{
            return err.response.data;
        });  
        return result;
};

export const updateUserService = async(data)=>{
    const result= await axiosInstance.post('/admin/update/user',data).then((res)=>{
        return res.data
        }).catch((err)=>{
            return err.response.data;
        });  
        return result;
};
export const deleteUserService = async(id)=>{
    const result= await axiosInstance.get(`/admin/del/user/${id}`).then((res)=>{
        return res.data
        }).catch((err)=>{
            return err.response.data;
        });  
        return result;
};
export const deleteRowCheckService = async(id)=>{
    const formdata = new FormData();
    id.map((e,index)=>{

        formdata.append(`id[${index}]`, e)
    })
    const result= await axiosInstance.post(`/admin/del/app/collection`, formdata).then((res)=>{
        return res.data
        }).catch((err)=>{
            return err.response.data;
        });  
        return result;
};


export const addUserService= async(data)=>{
    const result= await axiosInstance.post('/admin/add/user',data).then((res)=>{
        return res.data
        }).catch((err)=>{
            return err.response.data;
        });  
        return result;
}