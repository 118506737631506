/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-undef */
import { useState, useEffect, useMemo, useContext } from 'react';
import { MaterialReactTable, MRT_EditActionButtons as MrtTable } from 'material-react-table';
import Swal from 'sweetalert2';
// import { jsPDF } from 'jspdf'; //or use your library of choice here
// import autoTable from 'jspdf-autotable';
import { dataContext } from '../../contexts/DataProivder';
import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Container, Box, Button, IconButton, Tooltip, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

import {
  addApplicationsService,
  deleteApplicationService,
  getAllApplicationByFilesService,
  getAllApplicationsService,
  hiddenApplicationService,
  updateApplicationsService,
} from '../../services/applicationservices/applicationService';
import FileUploadModal from './FileUploadModal';
import { deleteRowCheckService } from 'src/services/dashboardservices/userManagementService';

// ----------------------------------------------------------------------

export default function AllApplications() {
  const [user] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isUpdatingUser, setIsUpdatingUser] = useState(false);
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const {applicationId,setApplicationId} = useContext(dataContext)
  const [statusValues, setStatusValues] = useState([
    {
      value: 'Positive',
      label: 'Positive',
    },
    {
      value: 'Negative',
      label: 'Negative',
    },
  ]);
  const currentYear = new Date().getFullYear();
  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });

  const columns = useMemo(() => [
    
    {
      accessorKey: 'year',
      header: 'Year',
      size: 150,
      editVariant:"select",
      editSelectOptions:Array.from({ length: currentYear - 1939 }, (_, index) => ({
        label: `${currentYear - index}`,
        value: `${currentYear - index}`,
      }))
    },
    {
      accessorKey: 'quarter',
      header: 'Quarter',
      size: 150,
    },
    {
      accessorKey: 'program_stream',
      header: 'Program Stream',
      size: 50,
    },
    {
      accessorKey: 'province',
      header: 'Province',
      size: 50,
    },
    {
      accessorKey: 'city',
      header: 'City',
      size: 50,
    },
    {
      accessorKey: 'postal_code',
      header: 'Postal Code',
      size: 50,
    },
    {
      accessorKey: 'employer',
      header: 'Employer',
      size: 50,
    },
    {
      accessorKey: 'status',
      header: 'Status',
      size: 50,
    
    },
    {
      accessorKey: 'lmia',
      header: 'LMIA',
      size: 150,
      editVariant: 'select',
      editSelectOptions:[
        {
          label:"Approved",
          value:"1",
        },
        {
          label:"Denied",
          value:"0",
        }
      ]
    },
    {
      accessorKey: 'positions',
      header: 'Positions',
      size: 150,
    },
   
  {
    accessorKey: 'noc_code',
    header: 'NOC Code',
    size: 50,
   
  },
  {
    accessorKey: 'noc_version',
    header: 'NOC Version',
    size: 50,
    editVariant: 'select',
    editSelectOptions: Array.from({ length: currentYear - 1939 }, (_, index) => ({
      label: `${currentYear - index}`,
      value: `${currentYear - index}`,
    })),
  },
  {
    accessorKey: 'noc_digit',
    header: 'NOC Digit',
    size: 50,
   
  },
    
    {
      accessorKey: 'description',
      header: 'Description',
      size: 50,
    },
    
    
    {
      accessorKey: 'address',
      header: 'Address',
      size: 50,
    },
    {
      accessorKey: 'occupation',
      header: 'Occupation',
      size: 150,
    },
    {
      accessorKey: 'incorporate_status',
      header: 'Incorporate Status',
      size: 150,
    },
  ]);

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };
  // const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);

  const getAllApplictions = async () => {
    setIsLoadingUsers(true);
    try {
      let result=null;
      if(applicationId !==0){
        result=await getAllApplicationByFilesService(applicationId)
      }
      else{

         result = await getAllApplicationsService();
      }

      if (result.data) {
        setTableData(result.data);
        setIsLoadingUsers(false);
        setApplicationId(0)
      }
    } catch (error) {
      setIsLoadingUsers(false);
      console.log(error);
    }
  };

  const handleCreateUser = async ({ values, table }) => {
    setIsCreatingUser(true);
    setIsLoadingUsers(true);
    const result = await addApplicationsService(values);

    if (result.status === true) {
      setIsCreatingUser(false);
      setIsLoadingUsers(false);
      setValidationErrors({});
      table.setEditingRow(null);
    } else {
      setIsCreatingUser(false);
      setIsLoadingUsers(false);
    }
  };

  //  updating user
  const handleUpdateApplication = async ({ values, table }) => {
    setIsUpdatingUser(true);
    setIsLoadingUsers(true);
    const result = await updateApplicationsService(values);

    console.log('handle update user ', result);
    if (result.status === true) {
      setIsUpdatingUser(false);
      setIsLoadingUsers(false);
      setValidationErrors({});
      table.setEditingRow(null);
    } else {
      setIsUpdatingUser(false);
      setIsLoadingUsers(false);
      setValidationErrors({});
    }
  };

  //  User Delete function
  const openDeleteConfirmModal = async (row) => {
    // alert(row.original.id)
    Swal.fire({
      title: 'Do you want to delete an application?',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setIsUpdatingUser(true);
        setIsLoadingUsers(true);

        const reuslt = await deleteApplicationService(row.original.id);
        if (reuslt.status) {
          Swal.fire({
            icon: 'success',
            title: 'Deleted!',
            text: 'Application has been deleted successfully.',
          });
          getAllApplictions();
          setIsUpdatingUser(false);
          setIsLoadingUsers(false);
          setValidationErrors({});
        } else {
          setIsUpdatingUser(false);
          setIsLoadingUsers(false);
          setValidationErrors({});
        }
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      }
    });
  };
  //  Application hidden employee function
  const hiddenEmployee = async (row) => {
    // alert(row.original.id)
    
        setIsUpdatingUser(true);
        setIsLoadingUsers(true);
       const data={
        emp_name:row.original.employer,
        hide:1
       }
        const reuslt = await hiddenApplicationService(data);
        if (reuslt.status) {
        
          getAllApplictions();
          setIsUpdatingUser(false);
          setIsLoadingUsers(false);
        
          Swal.fire({
            icon: 'success',
            title: 'Employer!',
            text: 'Employer has been hide  successfully.',
          });
        } 
        else {
          setIsUpdatingUser(false);
          setIsLoadingUsers(false);
        
        }
      
  };
  useEffect(() => {
    getAllApplictions();
  }, []);

  useEffect(() => {
    if (user?.length) {
      setTableData(user);
    }
  }, [user]);

  const toggleFileUploadModal = () => {
    setIsFileUploadModalOpen(!isFileUploadModalOpen);
  };

  const DeleteRowCollection = async (data,table) => {


    Swal.fire({
      title: 'Do you want to delete an application?',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setIsUpdatingUser(true);
        setIsLoadingUsers(true);

        const reuslt = await await deleteRowCheckService(data);
        if (reuslt.status) {
          Swal.fire({
            icon: 'success',
            title: 'Deleted!',
            text: 'Application has been deleted successfully.',
          });
          getAllApplictions();
          setIsUpdatingUser(false);
          setIsLoadingUsers(false);
          table.setRowSelection({});
          
         
        } else {
          setIsUpdatingUser(false);
          setIsLoadingUsers(false);
          
         
        }
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      }
    });
  };
  return (
    <Page title="Database">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs heading="Database" links={[{ name: '', href: '' }]} />

        <MaterialReactTable
          columns={columns}
          data={tableData}
          createDisplayMode="modal" // default ('row', and 'custom' are also available)
          editDisplayMode="modal"
          enableRowActions
          // createDisplayMode="row"
          // editDisplayMode="row"
          enableEditing
          onCreatingRowCancel={() => setValidationErrors({})}
          onCreatingRowSave={handleCreateUser}
          onEditingRowCancel={() => setValidationErrors({})}
          onEditingRowSave={handleUpdateApplication}
          enableRowSelection={true}
          // onRowSelectionChange={(row) => {console.log("the row selection changed",row)}}
          renderRowActions={({ row, table }) => (
            <>
              <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip title="Hide Employer">
                  <IconButton onClick={() => hiddenEmployee(row)}>
                    <RemoveRedEyeIcon />
                  </IconButton>
                </Tooltip>
                {/* <Tooltip title="Edit">
                  <IconButton onClick={() => table.setEditingRow(row)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip> */}
              
                <Tooltip title="Delete">
                  <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
          )}
          getRowId={(row) => row.id}
          renderTopToolbarCustomActions={({ table }) => {
            const handleDeactivate = (row) => {
              const dataarray = [];
              table.getSelectedRowModel().flatRows.map((row) => {
                dataarray.push(row.original.id);
              });
             DeleteRowCollection(dataarray,table);
            
            };
            return (
              <Box sx={{ display: 'flex', gap: '1rem' }}>
                {/* <Button
                  variant="contained"
                  onClick={() => {
                    table.setCreatingRow(true);
                  }}
                >
                  Create New Application
                </Button> */}
                {/* <Button variant="contained" onClick={toggleFileUploadModal}>
                  File
                </Button> */}
                <Button
                  disabled={table.getPrePaginationRowModel().rows.length === 0}
                  //export all rows, including from the next page, (still respects filtering and sorting)
                  onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
                  startIcon={<FileDownloadIcon />}
                >
                  Export CSV
                </Button>
                <Button
                color="error"
                // disabled={!table.getIsSomeRowsSelected()}
                onClick={handleDeactivate}
                variant="contained"
                // classes={!table.getIsSomeRowsSelected() ? "hidden":"block"}
                  style={{
                    display: table.getIsSomeRowsSelected()? 'block' : 'none',
                  }}
              >
                Delete All
              </Button>
              </Box>
            );
          }}
          positionActionsColumn="last"
          state={{
            isLoading: isLoadingUsers,
            isSaving: isCreatingUser || isUpdatingUser || isDeletingUser,
          }}
          renderCreateRowDialogContent={({ table, row, internalEditComponents }) => (
            <>
              <DialogTitle variant="h3">Create New Application</DialogTitle>
              <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                {internalEditComponents} {/* or render custom edit components here */}
              </DialogContent>
              <DialogActions>
                <MrtTable variant="text" table={table} row={row} />
              </DialogActions>
            </>
          )}
        />
        {/* <AddUser open={open} setOpen={setOpen} /> */}
        <FileUploadModal
          isOpen={isFileUploadModalOpen}
          onClose={toggleFileUploadModal}
          setIsLoadingUsers={setIsLoadingUsers}
          GetData={getAllApplictions}
          // Add any necessary props for file upload functionality
        />
      </Container>
    </Page>
  );
}
