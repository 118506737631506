
// import { useEffect, useMemo, useState } from 'react';
import { Grid, Container,Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// hooks
// import { useNavigate } from 'react-router';
// import { useSnackbar } from 'notistack';
// import MaterialReactTable from "material-react-table";
// import { useDispatch, useSelector } from 'react-redux';
// import axios from '../../utils/axios';
// import { getUser } from '../../redux/slices/user';
// import useAuth from '../../hooks/useAuth';
import useSettings from '../../hooks/useSettings';
// import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import TopCountryChart from '../../sections/charts/TopCountryChart';
import TopProvinceChart from '../../sections/charts/TopProvinceChart';
import TopCityChart from '../../sections/charts/TopCityChart';
// import { AppWidget } from '../../sections/@dashboard/general/app';
import TopUsers from '../../sections/tables/TopUsers';



// ----------------------------------------------------------------------

export default function Dashboard() {
  const { themeStretch } = useSettings();
  const theme = useTheme();

console.log(theme)



  return (
    <Page title="Dashboard">
      <Container maxWidth={themeStretch ? false : 'xl'}>
      <HeaderBreadcrumbs
          heading="Dashboard"
          links={[
            { name: '', href: '' },]}
          />
          <Typography variant='h4'>User Statistics</Typography>
        <Grid container spacing={3} style={{marginBottom:"30px"}}>
        {/* <Grid item xs={12} md={12} lg={12}>
            <Stack spacing={3}>
              <AppWidget title="Number of Users" total={76} icon={'eva:person-fill'} chartData={76} />
          </Stack>
          </Grid> */}
          <Grid item xs={12} md={4} lg={4}>
           <TopCountryChart />
        
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
        
           <TopProvinceChart />
         
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
          
           <TopCityChart />
          </Grid>
        
        
        </Grid>
        <Grid container spacing={10}>
        <Grid item xs={12} md={12} lg={12}>
          <TopUsers />
          </Grid>
          </Grid>
      </Container>
    </Page>
  );
}
