
import { Grid, Container,} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import useSettings from '../../hooks/useSettings';

import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections

import AllUsers from '../../sections/tables/AllUsers';



// ----------------------------------------------------------------------

export default function Users() {
  const { themeStretch } = useSettings();
  const theme = useTheme();

console.log(theme)



  return (
    <Page title="Dashboard">
      <Container maxWidth={themeStretch ? false : 'xl'}>
      <HeaderBreadcrumbs
          heading="Dashboard Users Management"
          links={[
            { name: '', href: '' },]}
          />
         
       
        <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <AllUsers />
          </Grid>
          </Grid>
      </Container>
    </Page>
  );
}
