import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
// import useAuth from '../hooks/useAuth';

import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
// import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
// import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import { PATH_DASHBOARD } from './paths';
import Dashboard from '../pages/Admin/Dashboard';
import Users from '../pages/Admin/Users';
import Blog from '../pages/Admin/Blog';
import Applications from '../pages/Admin/Applications';
import AllHiddenApplications from '../pages/Admin/AllHiddenApplications';
import ApplicationFiles from '../pages/Admin/ApplicationFiles';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  
  const navigateTo =PATH_DASHBOARD.dashboard.app;
 
  return useRoutes([
    {
      path: '/',
      element:<Login/>,
    },
    {
      path: '/dashboard',
      element:<Login />,
    },
 
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={navigateTo} replace />, index: true },
        { path: 'home', element: <Dashboard /> },
        { path: 'users', element: <Users /> },
        { path: 'blog', element: <Blog /> },
        { path: 'applications', element: <Applications /> },
        { path: 'hidden-employee', element: <AllHiddenApplications /> },
        { path: 'application-files', element: <ApplicationFiles /> },
      
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
// const Register = Loadable(lazy(() => import('../pages/auth/Register')));
// const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
// const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

const NotFound = Loadable(lazy(() => import('../pages/Page404')));

// SuperADmin

// Subadmin
// const SubAdminList = Loadable(lazy(() => import('../pages/SuperAdmin/Subadmin/List')));
// const SubAdminCreate = Loadable(lazy(() => import('../pages/SuperAdmin/Subadmin/Create')));

// seller
// const SellerList = Loadable(lazy(() => import('../pages/SuperAdmin/Seller/List')));
// const SellerCreate = Loadable(lazy(() => import('../pages/SuperAdmin/Seller/Create')));

// user
// const UserList = Loadable(lazy(() => import('../pages/SuperAdmin/User/List')));
// const UserCreate = Loadable(lazy(() => import('../pages/SuperAdmin/User/Create')));
