import { useEffect, useState } from 'react';

import { styled,useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card, CardHeader } from '@mui/material';
import { UserChartDetails } from '../../services/dashboardservices/userDetailsChart';
// ----------------------------------------------------------------------

export default function TopCityChart() {
  const theme = useTheme();
  const [chartData,setChartData]=useState([]);

 
  const hanldeChartData =async()=>{
    const result = await UserChartDetails();
    if(result){
      
      setChartData(result.data?.top_cities)
    }
      
    } 
 

 useEffect(() => {
  if (chartData.length === 0) {
    hanldeChartData();

  }
}, [chartData]);
  return (
    <Card>
    <CardHeader title="Top Cities" />
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
    <TableContainer sx={{ maxHeight: 300 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
            
                <TableCell> Name </TableCell>
                <TableCell> Count </TableCell>
          
            </TableRow>
          </TableHead>
          <TableBody>
          {chartData?.map((city,index)=>(
              <TableRow key={index}>
            
            <TableCell> {city?.city} </TableCell>
            <TableCell> {city?.total} </TableCell>
      
        </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      
    </Paper>
    </Card>
  );
}
