import axiosInstance from "../../utils/axios"


export const getAllBlogsService = async()=>{
    const result= await axiosInstance.get('/admin/get/all/blogs').then((res)=>{
        return res.data
        }).catch((err)=>{
            return err.response.data;
        });  
        return result;
};
export const addBlogService = async(data)=>{
 
    const  formdata = new FormData();
    formdata.append('name',data.name);
    formdata.append('description',data.description);
    formdata.append('category',data.category);
    formdata.append('thumbnail',data.thumbnail);
    const result= await axiosInstance.post('/admin/add/blog',formdata).then((res)=>{
        return res.data
        }).catch((err)=>{
            return err.response.data;
        });  
        return result;
};

export const updateBlogService = async(data)=>{
 
    const  formdata = new FormData();
    formdata.append('id',data.id);
    formdata.append('name',data.name);
    formdata.append('description',data.description);
    formdata.append('category',data.category);
    if(data.thumbnail){

        formdata.append('thumbnail',data.thumbnail);
    }
    const result= await axiosInstance.post('admin/update/blog',formdata).then((res)=>{
        return res.data
        }).catch((err)=>{
            return err.response.data;
        });  
        return result;
};



export const deleteBlogService = async(id)=>{
    const result= await axiosInstance.get(`/admin/del/blog/${id}`).then((res)=>{
        return res.data
        }).catch((err)=>{
            return err.response.data;
        });  
        return result;
};


